<template>
  <div class="col-md-12 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Firmas</h1>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
              <div class="form-group row">
              <form v-on:submit.prevent="searchItems({ key: keywords})">
                  <div class="input-group">
                    <input type="text" v-model="keywords" class="form-control" placeholder="" aria-label="Buscar por palabras claves" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <button type="submit" class="btn btn-outline-primary">Buscar</button>
                      <!--
                      <button
                        type="button"
                        v-on:click="newSearch()"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#searchModal"
                      >Búsqueda avanzada</button>                
                      <search-firma v-on:search="searchItems" v-bind:item="search"></search-firma>
                      -->
                    </div>
                  </div>                
                </form>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="">
              <button v-on:click="$router.push({ name: 'CreateFirma', params: { add: true, search: search } });" class="btn btn-primary"><i data-feather="plus-circle"></i>Agregar</button>
            </div>
            <br>
            <div v-if="filter" class="alert alert-primary" role="alert">
              {{ keywords }}
              <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <div class="table-responsive">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <td></td>
                      <td>Proveedor</td>
                      <td>Usuario</td>
                      <td>Predeterminar</td>
                      <td class="text-center"><input type="checkbox" v-model="allChecked" v-on:click="checkAllItems"></td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) in items">
                      <td class="text-center">
                        <button v-on:click="editItem(item._id)" class="btn btn-primary">Editar</button>
                      </td>
                      <td>{{ item.frm_proveedor_nombre }}</td>
                      <td>{{ item.frm_user }}</td>
                      <td>
                        <a v-if="!item.frm_default" href="#" v-on:click.prevent="showDefaultModal(item)">Establecer como predeterminado</a>
                        <p v-else class="font-weight-bold text-primary">(Predeterminado)</p>
                      </td>
                      <td class="text-center">
                        <input type="checkbox" v-bind:value="item._id" v-model="delItems">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br>
            <div>
              <button class="btn btn-danger" v-on:click="deleteItems" v-bind:disabled="!checkedItems">
                <i data-feather="delete"></i>Eliminar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>  

    <!-- Modal -->
    <div class="modal" id="defaultModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Confirmar</h5>
            </div>
            <div class="modal-body">
              <div style="text-align:center"><img src="/images/sign.png"></div>
              <div style="text-align:center"><p>¿Desea establecer esta firma como predeterminada?</p></div>
              <div style="text-align:center"><p class="font-weight-bold text-primary">{{ defaultSignObject.frm_user }}</p></div>
              <div class="form-row">
                <div class="form-group col-md-6 text-center">
                  <button type="button" class="btn btn-primary" v-on:click.prevent="setDefaultSign(defaultSignObject._id)">Aceptar</button>
                </div>
                <div class="form-group col-md-6 text-center">
                  <button type="button" class="btn btn-secondary" v-on:click.prevent="cancelDefaultSign">Cancelar</button>
                </div>
              </div>
            </div>
            <div class="modal-footer">

            </div>
          </div>
        </div>
    </div>
    <!-- End Modal -->        
  </div>
</template>

<script>
//import { VIcon } from 'vuetify/lib';
//import SearchFirma from "./SearchFirma.vue";
import { formatBoolean, formatDateUtc } from '../../plugins/filters';

export default {
  components: {
    //VIcon,
    //SearchFirma
  },
  data(){
    return{
      items: [],
      delItems: [], //List of items to delete
      allChecked: false, //Check all items
      keywords:'',
      search: {},
      filter: false, // Items are filtered
      defaultSignObject: {}
    }
  },
  filters: {
    formatBoolean,
    formatDateUtc
  },
  computed: {
    checkedItems(){
      if (this.delItems.length > 0){
        return true;
      } else {
        return false;
      }
    }
  },
  mounted: function() {
    feather.replace();
  },
  created: function()
  {
    if (this.$route.params.search) {
      this.search = this.$route.params.search;
      this.searchItems(this.search);
    } else {
      this.searchItems();
    }
  },
  methods: {
    deleteItem(id, index)
    {
      const response = confirm('Está seguro que desea eliminar?');
      if (response) {
        let uri = '/firmas/delete/'+id;
        this.axios.get(uri)
        .then(res => {
          this.items.splice(index, 1);
        })
        .catch(err => console.log(err));
      }
      return;
    },
    deleteItems(){
      if (this.delItems.length > 0){
        const response = confirm('Está seguro que desea eliminar los registros seleccionados?');
        if (response) {
          this.show = true;
          let uri = '/firmas/deleteitems';
          this.axios.post(uri, { delItems: this.delItems })
          .then(res => {
            this.show = false;
            if (this.filter){
              this.searchItems(this.search);
            } else {
              this.searchItems();
            }
          })
          .catch(err => {
            this.show = false;
            alert(err.response.data.msg);
            if (err.response.data.i > 0){
              if (this.filter){
                this.searchItems(this.search);
              } else {
                this.searchItems();
              }
            }
          });          
        }
      }
      return;
    },
    checkAllItems(){
      this.delItems = [];
      if (!this.allChecked){
        for (var i = 0; i < this.items.length; i++){
          this.delItems.push(this.items[i]._id);
        }
      }
    },
    searchItems(p){
      if (p != null){
        if (p.key){
          this.keywords = p.key;
        } else {
          this.keywords = '';
        }
      } else {
        p = {};
      }
      
      this.message = 'Buscando firmas';
      p.cmp_id = this.$store.state.company;
      p.frm_owner_id = this.$store.state.user;
      let uri = '/firmas/search';
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.post(uri, p)
      .then(response => {
        console.log(response);
        var list = response.data;

        for(let i = 0; i < list.length; i++){
          switch (list[i].frm_proveedor) {
            case 'firmese':
              list[i].frm_proveedor_nombre = 'Fírmese';
              break;
            default:
              break;
          }
        }

        this.items = list;
        this.search = p;
        this.delItems = [];
        this.filter = true;
      })
      .catch(err => {
        console.log(err);
        this.message = '¡Error al buscar las firmas. ' + err;
      });      
    },
    closeSearch(){
      this.filter = false;
      this.search = {};
      this.keywords = '';
      this.items = [];
      this.searchItems();
    },
    newSearch () {
      this.search = {};
    },
    createItem(){
      this.$router.push({ name: 'CreateFirma', params: { add: true, search: this.search } });
    },
    editItem(id){
      this.$router.push({ name: 'CreateFirma', params: { id: id, add: false, search: this.search } });
    },
    showDefaultModal(obj){
      this.defaultSignObject = { frm_user: obj.frm_user, _id: obj._id };
      $('#defaultModal').modal('show');
    },
    setDefaultSign(id){
      var p = {};
      let uri = '/firmas/default';
      p.cmp_id = this.$store.state.company;
      p.owner_id = this.$store.state.user;
      p.id = id;
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.post(uri, p)
      .then(response => {
        $('#defaultModal').modal('hide');
        if (this.filter){
          this.searchItems(this.search);
        } else {
          this.searchItems();
        }
      })
      .catch(err => {
        $('#defaultModal').modal('hide');
        if (this.filter){
          this.searchItems(this.search);
        } else {
          this.searchItems();
        }
      });
    },
    cancelDefaultEmail(){
      $('#defaultModal').modal('hide');
    }    
  }
}
</script>

<style>
.inactivo {
  /*
  background-color: #FFEAE8;
  */
  color: lightcoral;
  text-decoration: line-through;
}
</style>